<!-- 已废弃页面 无入口 -->
<template>
  <div class="content">
    <div class="infoTop">
      <p>{{ infoTitleText }}</p>
      <p>将用于奖励结算，请与信用卡申请人保持一致</p>
    </div>
    <div class="infoContent">
      <div :style="{height: infoHeight}">
        <div v-if="isShowNameOrCard" class="infoName">
          <p class="informationTitle">姓名</p>
          <input v-model="form.name" class="informationInp" type="text" placeholder="请输入姓名">
        </div>
        <div v-if="isShowNameOrCard" class="van-hairline--bottom" />
        <div class="infoCard">
          <p v-if="isShowNameOrCard" class="informationTitle">身份证号</p>
          <input v-model="form.idEntityNo" class="informationInp" type="text" placeholder="请输入身份证号">
        </div>
        <div v-if="isShowNameOrCard" class="van-hairline--bottom" />
        <div class="infoMobile">
          <p class="informationTitle">手机号</p>
          <input v-model="form.mobile" class="informationInp" type="number" placeholder="请输入手机号">
        </div>
        <div class="van-hairline--bottom" />
        <div v-if="isPerfect" class="infoNumber">
          <p class="informationTitle">短信验证码</p>
          <input v-model="form.code" class="informationInp" style="width: 75%;" placeholder="请输入收到的验证码" type="number">
          <button class="codeTextStyle" :style="{color:codeColor}" :disabled="disabled" @click="codeTextClick">
            {{ codeText }}
          </button>
        </div>
        <div v-if="isPerfect" class="van-hairline--bottom" />
      </div>
      <div class="infoService" @click="isSel">
        <img :src="UsersServicesImg" alt="">
        <div>
          <span>我已阅读并同意</span><span class="serviceContent" @click="lookService">《用户服务协议》</span>
        </div>
      </div>
      <div class="infoSubmit">
        <button class="saveBtn" @click="submit">确 定</button>
      </div>
    </div>
    <div class="infoBottom">
      <p class="sweetPromptWrapText1">温馨提示：</p>
      <p class="sweetPromptWrapText2">
        1、相关奖励信息请在「金融服务」-「奖励规则」页面统一查看；
      </p>
      <p class="sweetPromptWrapText2">
        2、在本平台申请信用卡不收取任何的费用，凡索取费用均为诈骗行为，
        申请信用卡授信额度与个人资质相关，凡承诺100%下卡或10万以上
        大额信用卡申请的都是骗子，请不要相信。
      </p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'

Vue.use(Toast)
import {
  getAppInfo,
  setSMSCode,
  createOrder
} from '@/services/comeOn.js'
import { formatPhone, hideRangeInString } from '@/utils/common/utils'

export default {
  name: 'InfoCollection',
  filters: {
    Phone_status(v) {
      return formatPhone(v)
    },
    idEntityNo_status(v) {
      return hideRangeInString(v, 6, 4)
    },
    name_status(v) {
      return hideRangeInString(v, 0, 1)
    }
  },
  data() {
    return {
      sourceSign: '2095CE15', // 渠道编码
      form: {
        name: '',
        idEntityNo: '',
        mobile: '',
        code: ''
      },
      infoTitleText: '请填写信息',
      infoHeight: '350px', // 填写信息高度
      isShowNameOrCard: true, // 是否显示姓名和身份证
      isPerfect: false, // 是否显示验证码
      codeColor: '#DCA57B', // 验证码字体颜色
      disabled: false, // 验证码是否被禁用
      codeNumber: 60,
      codeText: '发送验证码', // 验证码文字
      UsersServicesImg: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-06/12/yuelvhuiGaHQDmN1ZA1607227360.png', // 服务协议图片
      isService: true// 服务协议是否选择
    }
  },
  watch: {
    // 身份证
    'form.idEntityNo': function(newVal, oldVal) {
      if (newVal.length > 17) {
        this.form.idEntityNo = newVal.substring(0, 18)// 截取前4位
      }
    },
    // 手机号
    'form.mobile': function(newVal, oldVal) {
      this.isPerfect = newVal !== window.localStorage.getItem('mobile')
      if (newVal.length > 10) {
        this.form.mobile = newVal.substring(0, 11)
      }
    },
    // 短信验证码
    'form.code': function(newVal, oldVal) {
      if (newVal.length > 5) {
        this.form.code = newVal.substring(0, 6)// 截取前6位
      }
    }
  },
  created() {
    const {
      typeId = null,
      thirdSign = null,
      bankCardId = '',
      sharer = null,
      bankId = '',
      sourceSign = '2095CE15',
      gateWayId = null
    } = this.$route.query
    this.typeId = typeId ? Number(typeId) : ''
    this.getAppInfo()// get用户是否填写信息
    this.bankCardId = bankCardId // 银行标识2
    this.bankId = bankId // 银行id
    this.gateWayId = gateWayId ? Number(gateWayId) : ''// gateWayId
    // 渠道编码
    this.sourceSign = sourceSign !== '' ? sourceSign : '2095CE15'
    // 三方标识
    this.thirdSign = thirdSign
    // 分享者id
    this.sharer = sharer
    if (this.gateWayId === 1) {
      this.isShowNameOrCard = false
    }
    this.form.mobile = window.localStorage.getItem('mobile')
  },
  mounted() {

  },
  methods: {
    // 判断用户是否填写信息
    getAppInfo() {
      getAppInfo({
        mid: window.localStorage.getItem('uid'),
        source: 'h5'
      }).then(res => {
        if (Number(res.code) === 200) { // 有信息，确认或修改
          this.isPerfect = true// 验证码发送按钮
          this.infoTitleText = '请确认信息'
          this.form.name = res.data.name
          this.form.mobile = res.data.mobile
          this.form.idEntityNo = res.data.idEntityNo
        } else if (Number(res.code) === 300) { // 没有相关信息，请完善
          this.isPerfect = false
        }
      })
    },
    // get验证码
    codeTextClick() {
      const that = this
      if (this.form.mobile === '') {
        Toast('请填写手机号')
        return false
      } else {
        setSMSCode({
          mobile: that.form.mobile
        }).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            that.disabled = true// 禁用按钮
            that.codeColor = '#ccc'
            that.codeText = that.codeNumber + 's'
            const Interval = setInterval(function() {
              (that.codeNumber)--
              that.codeText = that.codeNumber + 's'
              if (that.codeNumber === 0) {
                clearInterval(Interval)
                that.codeText = '获取验证码'
                that.disabled = false// 启用按钮
                that.codeColor = '#1F5DEE'
                that.codeNumber = 60
              }
            }, 1000)
          } else {
            Toast(res.msg)
          }
        })
      }
    },
    // 服务协议选择
    isSel() {
      this.isService = !this.isService
      if (this.isService === true) {
        this.UsersServicesImg = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-06/12/yuelvhuiGaHQDmN1ZA1607227360.png'
      } else if (this.isService === false) {
        this.UsersServicesImg = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-06/12/yuelvhuipfeouetySQ1607227341.png'
      }
    },
    // 服务协议查看
    lookService() {
      this.$router.push({
        path: '/cardServiceIndex'
      })
    },
    // 确认
    submit() {
      const that = this
      if (this.form.name === '') {
        Toast('请输入申请人姓名')
        return
      } else if (this.form.idEntityNo === '') {
        Toast('请输入身份证信息')
        return
      } else if (this.form.mobile === '') {
        Toast('请输入手机号')
        return
      } else if (this.isService === false) {
        Toast('请阅读并选择服务协议')
        return
      }
      if (that.gateWayId === 1) {
        that.form.name = '京东卡'
        that.form.idEntityNo = Math.floor(Math.random() * 999999999999999999)// 18位随机数字
      }
      const createOrderData = {
        merMobile: that.form.mobile, // 手机号
        merIdentityNo: that.form.idEntityNo, // 身份证（银行列表gateWayId值为1时，这里传18位不可重复的随机数）
        merName: that.form.name, // 姓名（银行列表gateWayId值为1时，这里传固定的名称为'京东卡'）
        bankCardId: that.bankCardId, // 银行标识2（银行列表中的merCardId字段）
        bankId: that.bankId, // 银行id
        gateWayId: that.gateWayId, // 如果返回1页面不显示填写姓名和身份证
        thirdSign: that.thirdSign, // 三方标识
        sourceSign: that.sourceSign, // 渠道编码
        mid: window.localStorage.getItem('uid'), // 用户id（如果渠道编码是邻居团的则不传）
        sharer: that.sharer, // 分享者id
        typeId: that.typeId,
        bid: that.bankId,
        code: that.form.code// 验证码
      }
      createOrder(createOrderData).then(res => {
        if (Number(res.code) === 200) {
          window.location.replace(res.data.url)
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  /*height: 100%;*/
  margin: 0;
  padding: 0;
  background-color: #F8F8F8;
}

.content {
  position: absolute;
  padding: 33px 13px 64px 13px;
  text-align: left;

  .infoTop {
    p:nth-child(1) {
      font-size: 24px;
      color: #000;
    }

    p:nth-child(2) {
      font-size: 15px;
      color: #666;
      margin-top: 15px;
    }
  }

  .infoContent {
    margin-top: 23px;

    .informationTitle {
      width: 30%;
      -webkit-text-size-adjust: none;
      font-size: 15px;
      color: #000;
      text-align: left;
      font-weight: 600;
      line-height: 20px;
      margin-top: 23px;
    }

    .informationInp {
      width: 100%;
      background: none;
      outline: none;
      border: none;
      font-size: 15px;
      color: #000;
      padding: 0;
      height: 50px;
    }

    .informationInp::-webkit-input-placeholder { /* WebKit browsers */
      color: #999;
      font-size: 15px;
    }

    .codeTextStyle {
      background: none;
      border: none;
      width: 25%;
      padding: 0;
      /*color: #DCA57B;*/
      font-size: 15px;
      text-align: center;
    }
  }

  .infoService {
    margin-top: 25px;
    display: flex;
    font-size: 12px;

    img {
      width: 12px;
      height: 12px;
    }

    div {
      margin-left: 7px;

      span {
        font-size: 12px;
      }

      .serviceContent {
        color: #DCA57B;
      }
    }
  }

  .infoSubmit {
    margin-top: 14px;

    .saveBtn {
      width: 100%;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border-radius: 7px;
      background: -webkit-linear-gradient(top, #FFD6B7, #DCA57B);
      font-size: 15px;
      color: #fff;
    }
  }

  .infoBottom {
    margin-top: 13px;

    .sweetPromptWrapText1 {
      font-size: 13px;
      color: #333;
      font-weight: 500;
    }

    .sweetPromptWrapText2 {
      margin-top: 9px;
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
